/**
 * ハンバーガーメニュー
 * @module humbergerMenu
 * @param {Object} object セレクター情報オブジェクト
 * @param {string} object.openBtn ナビオープンボタンのセレクター
 * @param {string} object.closeBtn ナビクローズボタンのセレクター
 * @param {string} object.navigation ナビゲーションのセレクター
 * @param {string} object.overlay オーバーレイのセレクター
 * @param {string} object.grantClass 付与するクラス名
 * @return {void} 返り値なし
 */
import { getCurrentPagefileName } from '~/modules/getCurrentPagefileName';
export var humbergerMenu = function (object) {
    // 初期設定
    var btnOpen = document.querySelector(object.openBtn);
    var btnClose = object.closeBtn
        ? document.querySelector(object.closeBtn)
        : undefined;
    var nav = document.querySelector(object.navigation);
    var overlay = document.querySelector(object.overlay);
    var header = document.querySelector('header');
    var grantClass = object.grantClass;
    // URLからカレントのファイル名を取得する
    var filename = getCurrentPagefileName();
    // 関数
    var navOpen = function () {
        btnOpen.classList.add(grantClass);
        btnClose && btnClose.classList.add(grantClass);
        nav.classList.add(grantClass);
        overlay.classList.add(grantClass);
        header.classList.add(grantClass);
    };
    var navClose = function () {
        btnOpen.classList.remove(grantClass);
        btnClose && btnClose.classList.remove(grantClass);
        nav.classList.remove(grantClass);
        overlay.classList.remove(grantClass);
        header.classList.remove(grantClass);
    };
    // 処理
    btnOpen.addEventListener('click', function () {
        return btnOpen.classList.contains(grantClass) ? navClose() : navOpen();
    });
    btnClose && btnClose.addEventListener('click', function () { return navClose(); });
    overlay.addEventListener('click', function () { return navClose(); });
    nav.querySelectorAll('a[href*="#"]').forEach(function (el) {
        el.addEventListener('click', function () {
            var href = el.getAttribute('href');
            if (href.match(filename) || href.match(/^#/) || href.match(/^\.\/#/)) {
                return navClose();
            }
        });
    });
};
